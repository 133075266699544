.container {
    padding-top: 150px;
    padding-bottom: 100px;
  }
  
  .shedule-image-position {
    position: absolute;
    height: auto;
    width: 70%;
    right: 0px;
    top: 330px;
    background-color: transparent;
  }

  .schedule-image-size{
      width: 90%
  }

  .date-image-position {
    position: absolute;
    height: auto;
    width: auto;
    left: 0%;
    top: 170px;
    background-color: transparent;
  }

  .date-image-size{
      width: 55%
  }
  
  @media only screen and (max-width: 991px) {
    .container {
      padding-top: 100px;
      padding-bottom: 40px;
    }

    .shedule-image-position {
        right: 5px;
        top: 340px;
      }
    
      .schedule-image-size{
          width: 80%
      }

      .date-image-position {
        left: 5%;
        top: 170px;
      }
    
      .date-image-size{
          width: 55%
      }
      

}
  
  @media only screen and (max-width: 600px) {
 
           
    .shedule-image-position {
        right: 5px;
        top: 168px;
      }
    
      .schedule-image-size{
          width: 100%
      }

      .date-image-position {
        left: 0%;
        top: 55px;
      }
    
      .date-image-size{
          width: 65%
      }


  }
  