.background{

  background-color: #fff;
  position: relative;
  

}


.background-image{
  position: absolute;
  top: -100px;
  right: 0;
  width: 100vw;
  height: 140vh;
  background-color: transparent;
  background-image: url("./assets/Path.png");
  background-repeat: no-repeat;
  z-index: 99 !important;
  background-position: 110% -30px;
   background-size: 60% 110%;
}


.background-dots-one{
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffb9cb' fill-opacity='0.41' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='2'/%3E%3Ccircle cx='13' cy='50' r='3'/%3E%3C/g%3E%3C/svg%3E");
  position: absolute;
  top: 245px;
  left: 0;
  width: 50vw;
  height: 230px;
  background-color: transparent;
}



.background-dots-two{
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffb9cb' fill-opacity='0.41' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='2'/%3E%3Ccircle cx='13' cy='50' r='3'/%3E%3C/g%3E%3C/svg%3E");
  position: absolute;
  top: 430vh;
  right: 300px;
  width: 300px;
  height: 300px;
  background-color: transparent;
}




.heading{
  font-family: 'Heebo', sans-serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 65px;
  color: #191A1B;
  margin: 0px !important
}


.subheading
{
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #191A1B;
  line-height: 35px;
  margin: 0px !important
}


.title{
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #191A1B;
  margin: 0px  
}

.body{
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #191A1B;
  margin: 0px !important
}

.header-menu-title{
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #191A1B;
  opacity: 1;
  margin: auto 20px !important;
  cursor: pointer;
  transition: all .5s
}


.list-item{
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-top: 4px !important;
  cursor: pointer;
}


.header-menu-title:hover{
  opacity: .5;
}

.link{
  font-family: 'Open Sans', sans-serif;
  font-size: 21px;
  font-weight: 600;
  color: #2181F7;
  line-height: 50px;
  margin:  0px !important;
  cursor: pointer;
  transition: all .5s
}

.link:hover{
  color: #FB625A;
}


.button-primary{
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  cursor: pointer !important;
  background-color: #2181F7;
  box-shadow: 0 15px 25px 0 rgba(33,129,247,0.38);
  border: 1px solid #2181F7;
  border-radius: 4px;
  padding: 10px 20px;
  transition: all .5s;
  z-index: 9999
}


.button-primary:hover{
  background-color: #FB625A !important;
  box-shadow: 0 15px 20px 0 rgb(251,98,90,0.38) !important;
  border: 1px solid #FB625A !important;
}



.button-primary-small{
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  background-color: #2181F7;
  box-shadow: 0 15px 25px 0 rgba(33,129,247,0.38);
  border: 1px solid #2181F7;
  border-radius: 40px;
  padding: 10px 25px;
  transition: all .5s
}

.button-primary-small:hover{
  background-color: #FB625A;
  box-shadow: 0 15px 20px 0 rgb(251,98,90,0.38);
  border: 1px solid #FB625A;
}


.button-secondary{
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #2181F7;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #2181F7;
  border-radius: 4px;
  padding: 10px 20px
}





@media only screen and (max-width:991px)
{

  .heading{
    font-size: 40px;
    line-height: 42px;
  }

  .title{
    font-size: 17px;
  }

  .body{
    font-size: 15px;
  }
  
  .link{
    font-size: 17px;
  }

  .subheading
{
  font-size: 20px !important; 
  line-height: 28px;
}


.background-image{
  position: absolute;
  top: -100px;
  right: 0;
  width: 104vw;
  height: 100vh;
  background-color: transparent;
  background-image: url("./assets/Path.png");
  background-repeat: no-repeat;
  z-index: 99 !important;
  background-position: 100% 0%;
   background-size: 100% 100%;
}





}


@media only screen and (max-width:600px)
{

}
   
