.signin-box {
  background: #ffffff;
  width: 100%;
  min-height: 500px;
  height: auto;
  border-radius: 30px;
  box-shadow: 0 14px 45px 0 rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999 !important;
}

.signin-form-padding{
 padding: 0 auto ;
 box-sizing: border-box

 
}

.signin-box-padding{
  padding: 60px;
  padding-top: 60px;
  z-index: 999999 !important
}




@media only screen and (max-width:991px)
{
  .signin-box-padding{
    padding: 0px;
  padding-top: 60px

  }

  .signin-box {
    background: #ffffff;
    width: 100%;
    min-height: 500px;
    height: auto;
    border-radius: 40px;
    box-shadow: 0 14px 45px 0 rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999 !important;
  }
  
  
}
   




@media only screen and (max-width:600px)
{

  .signin-form-padding{
    padding: 0 50px !important
   }
   

}
   
