
.container{
    padding-top: 80px;
    padding-bottom: 80px
}

.landing-heading-box{
    display: flex;
    padding: 40px;
    align-items: center;
    width:60vw; 
    background-color: transparent
}

.landing-signup-box{
    display: flex;
    padding: 80px 40px;
    align-items: center;
    background-color: #ffffff
}

.landing-trial-box{
    display: flex;
    padding: 40px;
    align-items: center;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffb9cb' fill-opacity='0.41' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='2'/%3E%3Ccircle cx='13' cy='50' r='3'/%3E%3C/g%3E%3C/svg%3E")
}


.landing-clients-container{
    padding: 80px 5px 80px 5px;
}

@media only screen and (max-width:991px)
{

    .container{
        padding-top: 20px;
        padding-bottom: 0px
    }
    
    .landing-heading-box{
        padding: 40px;
        align-items: center;
        width:80vw; 
        background-color: transparent
    }
    .landing-signup-box{
        padding: 0px 40px;
        /* padding-bottom: 80px */
    }

    .landing-trial-box{
        
        background-image: none
    }

    
    .landing-clients-container{
        padding: 0px 5px 20px 5px
    }

}


@media only screen and (max-width:600px)
{

}
   
