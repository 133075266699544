.integrate-container {
  padding-top: 250px;
  padding-bottom: 250px;
}

.palylist-image-one-position {
  position: absolute;
  height: auto;
  width: auto;
  left: 143px;
  top: 220px;
  background-color: transparent;
}

.palylist-image-two-position {
  position: absolute;
  height: auto;
  width: auto;
  left: 143px;
  top: 290px;
  background-color: transparent;
}

.palylist-image-active-position {
  position: absolute;
  height: auto;
  width: auto;
  left: 155px;
  top: 372px;
  background-color: transparent;
}

.palylist-image-size {
  width: 330px;
}

.palylist-image-active-size {
  width: 350px;
}

@media only screen and (max-width: 991px) {
  .integrate-container {
    padding-top: 200px;
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .palylist-image-one-position {
    left: 65px;
    top: 125px;
  }

  .palylist-image-two-position {
    left: 65px;
    top: 155px;
  }

  .palylist-image-active-position {
    left: 72px;
    top: 192px;
  }

  .palylist-image-size {
    width: 160px;
  }

  .palylist-image-active-size {
    width: 170px;
  }
}
