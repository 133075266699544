
.container{
    padding-top: 80px;
    padding-bottom: 80px
}

.upload-image-position{
    position: absolute;
    height: 80%;
    width: 80%;
    left: 200px;
    top: 50px;
    background-color: transparent
}


.list-image-position{
    position: absolute;
    right: -110px;
    top: 215px;
    background-color: transparent
}

.list-image{
    width:260px
}


@media only screen and (max-width:991px)
{

    .container{
        padding-top: 20px;
        padding-bottom: 0px
    }


    .upload-image-position{
        left: 150px;
        top: 20px;
    }

    .list-image-position{
        right: 50px;
        top: 150px;
    }
    
    .list-image{
        width:184px
    }
    
}


@media only screen and (max-width:600px)
{  
    

    
    .upload-image-position{
        left: 60px;
        top: 20px;
    }

    .list-image-position{
        right: 10px;
        top: 50px;
    }

    .list-image{
        width:144px
    }
}
   
