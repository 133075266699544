

.payment-box {
  background: #fff;
  width: 100%;
  height: 500px;
  border-radius: 30px;
  box-shadow: 0 14px 45px 0 rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999 !important;
}


.payment-box-padding{
  justify-content: flex-start;
  padding: 0px 60px 0px 60px
}


.plan-box-padding{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px 40px 40px;
  height: 100%;
  widows: 100%;
  padding-bottom: 0
}



.card-tab-button-active{
    font-family: 'Open Sans', sans-serif;
    font-size: 10px;
    font-weight: 300;
    color: #fff;
    
    cursor: pointer;
    
    background-color: #2181F7;
    box-shadow: 0 15px 25px 0 rgba(33,129,247,0.38);
    border: none;
    border-radius: 4px;
  
    padding: 5px 10px;
    margin-right: 5px
}


.card-tab-button{
    font-family: 'Open Sans', sans-serif;
    font-size: 10px;
    font-weight: 300;
    color: #2181F7;
  
    cursor: pointer;
    
    background-color: #fff;
    /* box-shadow: 0 15px 25px 0 rgba(33,129,247,0.38); */
    border: none;
    outline: none;
    border-radius: 4px;
  
    padding: 5px 10px;
    margin: 5px
}



.card-heading{
    font-family: 'Heebo', sans-serif;
    font-size: 35px;
    font-weight: 500;
    line-height:1;
    color: #191A1B;
    margin: 0px !important
     
  }
  
  
  .subheading
  {
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #191A1B;
    margin: 0px !important
  }
  
  
  .card-title{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #191A1B;
    margin: 0px !important;
    margin-bottom: 5px !important
  }


  .card-subtitle{
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #2181F7;
    margin: 0px !important
  }


  .card-body{
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #191A1B;
    margin: 0px !important
  }
 
  .card-body-small{
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    /* line-height: 15px; */
    color: #191A1B;
    margin: 0px !important
  }


  
.card-link{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #2181F7;
    line-height: 50px;
    margin:  0px !important;
    cursor: pointer;
  }
  
  .payment-box-gradient {
    background: rgb(251, 98, 90);
    background: linear-gradient(159deg, rgba(251,98,90,1) 47%, rgba(212,87,131,1) 81%);
    width: 100%;
    height: 500px;
    border-radius: 40px;
    /* box-shadow: 0px 1px 6px 0px rgba(126, 126, 126, 1); */
  box-shadow: 0 14px 45px 0 rgba(0,0,0,0.1);

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999 !important;
  }




  @media only screen and (max-width:991px)
{
   
  .plan-box-padding{
   
    padding: 40px 5px 40px 5px;
  }
    

  .payment-box-padding{
    justify-content: flex-start;
    padding: 0px 40px 0px 40px
  }


}